import React from 'react';
import { Statistic } from 'antd';
import {
    Card,
    Col,
  } from "reactstrap";


function StatisticCard({ label, value, icon, onClick }) {
    return (
        <Col lg={3} md={3} xs={12}>
            <Card onClick={onClick}>
                <Statistic
                    title={label}
                    value={value}
                    valueStyle={{ color: '#ae58f5' }}
                    prefix={icon}
                    suffix="total"
                />
            </Card>
        </Col>
    );
}

export default StatisticCard;
