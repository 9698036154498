import React, { useState, useContext } from 'react';
import { signInWithEmailAndPassword, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { auth } from '../../services/firebase';
import { NavLink, useHistory } from 'react-router-dom'
import { Box, Button, Grid, Tabs, TextField } from '@mui/material';
import mockLogo from '../../../src/assets/img/Mockup.webp'
import Background from '../../../src/assets/img/background.webp';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import NotificationAlert from "react-notification-alert";
import { AuthContext } from "../../contexts/AuthContext"
import GoogleButton from 'react-google-button'
import { Divider } from '@mui/material';

function TabPanel(props) {
  const { children, value, index, ...other } = props;


  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}


function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    'aria-controls': `simple-tabpanel-${index}`,
  };
}

const Login = (props) => {
  const navigate = useHistory();
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [value, setValue] = React.useState(0);

  const { user, setUser } = React.useContext(AuthContext)

  const notificationAlertRef = React.useRef(null);
  const notify = (place, message, type) => {
    //  type = "primary";
    // type = "success";
    // type = "danger";
    // type = "warning";
    // type = "info";

    var options = {};
    options = {
      place: place,
      message: (
        message
      ),
      type: type,
      icon: "tim-icons icon-bell-55",
      autoDismiss: 7
    };
    notificationAlertRef.current.notificationAlert(options);
  };

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  // google
  const googleSubmit = async (e) => {
    e.preventDefault()
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider).then((userCredential) => {
      // Signed in 
      const user = userCredential.user;
      // const userHelper = new UserHelper()
      // userHelper.getUser(userCredential.user.uid).then(async (data) => {
      //   console.log(data)
      //   if (data === undefined) {
      //     navigate("/login")
      //   } else {
      //     data.uid = userCredential.user.uid
      //     setParkshareUser(data)
      //   }


      // })
      navigate("/")
      console.log(user);
    })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode, errorMessage)
      });

  }
  // Normal
  const onLogin = (e) => {
    e.preventDefault();
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed in 
        console.log(userCredential.user)
        const user = userCredential.user;
        let message = (<div>
          <div>
            Welcome {userCredential.user.email}.
          </div>
        </div>);
        notify("tl", message, "success");
        setUser(user)
        navigate.push("/dashboard")
      })
      .catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(errorCode, errorMessage)

        notify("tl", errorMessage, "danger");
      });


  }

  return (
    <>
      <NotificationAlert ref={notificationAlertRef} />
      <div style={{ minHeight: "100vh", minWidth: "100vw", backgroundImage: "url(" + Background + ")", display: "block", position: "absolute", backgroundSize: "cover" }}>
        <Grid container spacing={2} style={{ minHeight: "70vh", paddingTop: "15%" }}>
          <Grid item lg={3} md={0} xs={12} container>

          </Grid>
          <Grid item lg={3} md={6} xs={12} container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center"
          >
            <Box
              component="img"
              sx={{
                height: "70%",
                width: "90%",
                display: { xs: 'none', md: 'flex' }
              }}

              src={mockLogo}
            />
          </Grid>
          <Grid item lg={4} md={6} xs={12} container
            spacing={0}
            direction="column"
            alignItems="center"
            justifyContent="center" style={{ backgroundColor: "white" }}
          >
            <h1>Parkshare Admin</h1>
            <Box sx={{ borderBottom: 1, borderColor: 'divider', width: "100%", marginTop: "20px", position: "flex" }}>
              <Tabs value={value} onChange={handleChange} aria-label="basic tabs example" textColor="secondary"
                indicatorColor="secondary" variant="fullWidth" sx={{ width: "100%" }}>
                <Tab sx={{ width: "50%" }} label="Login" {...a11yProps(0)} />
                <Tab sx={{ width: "50%" }} label="Information" {...a11yProps(1)} />
              </Tabs>
            </Box>
            <TabPanel value={value} index={0}>
              <form className="mt-8 space-y-6" style={{ minHeight: "30vh", minWidth: "30vw" }} >


                <TextField id="outlined-basic" label="Email" variant="outlined" value={email}
                  onChange={(e) => setEmail(e.target.value)} style={{ minWidth: "100%" }} />
                <br /><br />
                <TextField
                  style={{ width: "100%" }}
                  id="outlined-password-input"
                  label="Password"
                  type="password"
                  autoComplete="current-password"
                  required
                  onChange={(e) => setPassword(e.target.value)}
                />
                <br /><br />
                <Button onClick={onLogin} variant="contained" style={{ backgroundColor: "#9753f7", color: "white", marginRight: "5px" }}>Login</Button>
                <br /><br />
                <Divider></Divider>
                <br />
                <div>
                  <GoogleButton style={{ width: "100%" }}
                    onClick={googleSubmit}
                  />

                </div>

              </form>
            </TabPanel>
            {/* Signup */}
            <TabPanel value={value} index={1}>
              <div style={{ minHeight: "30vh", minWidth: "30vw" }}>
                <h1>This is our admin portal.</h1>
                <p>Unforunatly this is only for our staff and not open for everyone. </p>
                <p>Feel free to use our app or go to <a href="https://parkshare.co.za">Website </a> </p>
                <br />
                <p>If you are part of the team please request access if you dot have. </p>
              </div>
            </TabPanel>


          </Grid>
          <Grid item lg={3} md={0} xs={12} container>

          </Grid>
        </Grid>
      </div>

    </>
  )
}

export default Login