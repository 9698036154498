

import React, { useState, useEffect, useContext } from 'react';
import { ThreeCircles } from 'react-loader-spinner'
import NotificationAlert from "react-notification-alert";
import Form from 'react-bootstrap/Form';
import "../../assets/css/black-dashboard-react.css"
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Row,
  Col
} from "reactstrap";
import { ThemeContext } from "../../contexts/ThemeContext"
import { useLocation, useHistory } from "react-router-dom";
import { db } from "../../services/firebase";
import { doc, getDoc, collection, query, where, getDocs } from "firebase/firestore";
import * as Sentry from "@sentry/react";
import TextField from '@mui/material/TextField';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
import Button from 'react-bootstrap/Button';

export default function ReportOverview(props) {

  let history = useHistory();
  const location = useLocation();
  console.log("State got:", location)
  const theme = useContext(ThemeContext);

  const [loaded, setLoaded] = useState(false);
  const [reporter, setReporter] = useState({})
  const [spot, setSpot] = useState({})
  const [host, setHost] = useState({})
  const [booking, setBooking] = useState({})
  const [unknown, setUnknown] = useState(false)

  const [responded, setResponded] = useState(false);

  const handleResponded = (event) => {
    setResponded(event.target.checked);
  };

  const notificationAlertRef = React.useRef(null);
  const notify = (place, message, type) => {

    var options = {};
    options = {
      place: place,
      message: (
        message
      ),
      type: type,
      icon: "tim-icons icon-bell-55",
      autoDismiss: 7
    };
    notificationAlertRef.current.notificationAlert(options);
  };



  useEffect(() => {
    let message = (<div>
      <div>
        Could not load corresponding data
      </div>
    </div>)


    const getRelatedData = async () => {
      console.log("Getting data from:", location.state.report)
      console.log("User:", location.state.report.reportedBy)
      const docRef = doc(db, "users", location.state.report.reportedBy);
      const docSnap = await getDoc(docRef);
      console.log(docSnap)
      if (docSnap.exists()) {
        console.log("Document data:", docSnap.data());
        setReporter(docSnap.data())
      } else {
        notify("tl", message, "danger")
      }

      if (location.state.report.linked.type === "spot") {
        const docRef = doc(db, "spots", location.state.report.linked.id);
        const docSnap = await getDoc(docRef);
        console.log(docSnap)
        if (docSnap.exists()) {
          console.log("Document data:", docSnap.data());
          setSpot(docSnap.data())
        } else {
          notify("tl", message, "danger")
        }
      } else if (location.state.report.linked.type === "host") {
        const docRef = doc(db, "users", location.state.report.linked.id);
        const docSnap = await getDoc(docRef);
        console.log(docSnap)
        if (docSnap.exists()) {
          console.log("Document data:", docSnap.data());
          setHost(docSnap.data())
        } else {
          notify("tl", message, "danger")
        }
      } else if (location.state.report.linked.type === "booking") {
        const docRef = doc(db, "bookings", location.state.report.linked.id);
        const docSnap = await getDoc(docRef);
        console.log(docSnap)
        if (docSnap.exists()) {
          console.log("Document data:", docSnap.data());
          setBooking(docSnap.data())
        } else {
          notify("tl", message, "danger")
        }
      } else {
        setUnknown(true)
      }
      setLoaded(true)
    }

    getRelatedData()


  }, [])


  return (
    <>
      <NotificationAlert ref={notificationAlertRef} />
      {loaded ? (
        <>
          <div className="content">
            {location.state.report !== undefined ? (
              <>

                <Row>
                  {/* Report */}
                  <Col md="12">
                    <Card className="card-plain">
                      <CardHeader>
                        <CardTitle tag="h4">Report details</CardTitle>
                        <p className="category">Details logged by the reporter. &nbsp;</p>
                      </CardHeader>
                      <CardBody>

                        <Form>
                          <Row>
                            <Col lg={4} xs={12}>
                              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <Form.Label>Title</Form.Label>
                                <Form.Control
                                  type="name"
                                  disabled
                                  value={location.state.report.title || ''}
                                />
                              </Form.Group>
                            </Col>


                            <Col lg={12} xs={12}>
                              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <TextField
                                  style={{ width: "100%" }}
                                  id="outlined-multiline-static"
                                  label="Description"
                                  multiline
                                  rows={4}
                                  disabled
                                  value={location.state.report.message}
                                />
                              </Form.Group>
                            </Col>
                            <Col lg={12} xs={12}>
                              <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                <TextField
                                  style={{ width: "70%" }}
                                  id="outlined-multiline-static"
                                  label="Admin Notes"
                                  multiline
                                  rows={4}
                                  defaultValue="Default Value"
                                  value={spot.description}
                                />
                                <FormControlLabel
                                  label="Responded to"
                                  control={<Checkbox checked={responded} onChange={handleResponded} />}
                                />
                              </Form.Group>
                            </Col>
                          </Row>
                        </Form>

                      </CardBody>
                    </Card>
                  </Col>
                  {/*  Reporter */}
                  <Col md="12">
                    <Card className="card-plain">
                      <CardHeader>
                        <CardTitle tag="h4">Reporter</CardTitle>
                        <p className="category">User who reported the incident . &nbsp;</p>
                      </CardHeader>
                      {!unknown ? (
                        <CardBody>
                          {reporter !== {} ? (
                            <Form>
                              <Row>
                                <Col lg={4} xs={12}>
                                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Name</Form.Label>
                                    <Form.Control
                                      type="name"
                                      disabled
                                      value={reporter.person.firstName || ''}
                                    />
                                  </Form.Group>
                                </Col>

                                <Col lg={4} xs={12}>
                                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Last Name</Form.Label>
                                    <Form.Control
                                      type="lastName"
                                      disabled
                                      value={reporter.person.lastName || ''}
                                    />
                                  </Form.Group>
                                </Col>
                                <Col lg={4} xs={12}>
                                  <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control
                                      type="email"
                                      disabled
                                      value={reporter.person.email || ''}
                                    />
                                  </Form.Group>
                                </Col>
                              </Row>
                            </Form>
                          ) : (
                            <p>We could not find the user who reported the issue</p>
                          )}


                        </CardBody>

                      ) : (

                        <CardBody>
                          <p>This type of report has not been catered for. Please contactid</p>

                        </CardBody>

                      )}
                    </Card>
                  </Col>
                  {/* Spot */}
                  {Object.keys(spot).length !== 0 && (
                    <Col md="12">
                      <Card className="card-plain">
                        <CardHeader>
                          <CardTitle tag="h4">Spot</CardTitle>
                          <p className="category">The spot the incident was logged against. &nbsp;</p>
                        </CardHeader>
                        <CardBody>
                          <Form>
                            <Row>
                              <Col lg={4} xs={12}>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                  <Form.Label>Name</Form.Label>
                                  <Form.Control
                                    type="name"
                                    disabled
                                    value={spot.name || ''}
                                  />
                                </Form.Group>
                              </Col>

                              <Col lg={4} xs={12}>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                  <Form.Label>Address</Form.Label>
                                  <Form.Control
                                    type="lastName"
                                    disabled
                                    value={spot.location.formal || ''}
                                  />
                                </Form.Group>
                              </Col>
                              <Col lg={4} xs={12}>
                                <Button
                                  variant="primary"
                                  color="primary"
                                  size="small"
                                  style={{ marginLeft: 16 }}
                                  onClick={() => {
                                    history.push({
                                      pathname: '/details',
                                      state: { spot: spot }
                                    })
                                  }}
                                >
                                  Spot View
                                </Button>
                              </Col>
                            </Row>
                          </Form>
                        </CardBody>
                      </Card>
                    </Col>
                  )}
                  {/* Host */}
                  {Object.keys(host).length !== 0 && (
                    <Col md="12">
                      <Card className="card-plain">
                        <CardHeader>
                          <CardTitle tag="h4">Host</CardTitle>
                          <p className="category">The host the incident was logged against. &nbsp;</p>
                        </CardHeader>
                        <CardBody>
                          <Form>
                            <Row>
                              <Col lg={4} xs={12}>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                  <Form.Label>Name</Form.Label>
                                  <Form.Control
                                    type="name"
                                    disabled
                                    value={host.person.firstName || ''}
                                  />
                                </Form.Group>
                              </Col>

                              <Col lg={4} xs={12}>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                  <Form.Label>Last Name</Form.Label>
                                  <Form.Control
                                    type="lastName"
                                    disabled
                                    value={host.person.lastName || ''}
                                  />
                                </Form.Group>
                              </Col>
                              <Col lg={4} xs={12}>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                
                                <Button
                                  variant="primary"
                                  color="primary"
                                  size="small"
                                  style={{ marginLeft: 16 }}
                                  onClick={() => {
                                    history.push({
                                      pathname: '/user',
                                      state: { user: host }
                                    })
                                  }}
                                >
                                  Host View
                                </Button>
                                </Form.Group>
                              </Col>
                            </Row>
                          </Form>
                        </CardBody>
                      </Card>
                    </Col>
                  )}
                  {/* Booking */}
                  {Object.keys(booking).length !== 0 && (
                   
                    <Col md="12">
                      <Card className="card-plain">
                        <CardHeader>
                          <CardTitle tag="h4">Booking</CardTitle>
                          <p className="category">The booking the incident was logged against. &nbsp;</p>
                        </CardHeader>
                        <CardBody>
                          <Form>
                            <Row>
                              <Col lg={4} xs={12}>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                  <Form.Label>Name</Form.Label>
                                  <Form.Control
                                    type="name"
                                    disabled
                                    value={reporter.person.firstName || ''}
                                  />
                                </Form.Group>
                              </Col>

                              <Col lg={4} xs={12}>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                  <Form.Label>Last Name</Form.Label>
                                  <Form.Control
                                    type="lastName"
                                    disabled
                                    value={reporter.person.lastName || ''}
                                  />
                                </Form.Group>
                              </Col>
                              <Col lg={4} xs={12}>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                                  <Button
                                  variant="primary"
                                  color="primary"
                                  size="small"
                                  style={{ marginLeft: 16 }}
                                  onClick={() => {
                                    history.push({
                                      pathname: '/booking',
                                      state: { booking: host }
                                    })
                                  }}
                                >
                                  Booking View
                                </Button>
                                </Form.Group>
                              </Col>
                            </Row>
                          </Form>
                        </CardBody>
                      </Card>
                    </Col>
                  )}
                </Row>
              </>
            ) : (<>
              <Row >

                <Col md="12" >
                  <Card className="card-plain" style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    alignContent: 'center',
                    height: '100%',
                    width: '100%',
                  }} >
                    <CardHeader >
                      <CardTitle tag="h4">We could not find this report..</CardTitle>

                    </CardHeader>
                    <CardBody>
                      <p className="category">If this is a mistake please contact IT</p><br />
                    </CardBody>
                  </Card>
                </Col>
              </Row>
            </>)}

          </div>
        </>)
        : (
          <div className="content">
            <Row>

              <Col md="12">
                <Card className="card-plain">
                  <CardHeader>
                    <CardTitle tag="h4">Report Overview</CardTitle>
                  </CardHeader>
                  <CardBody style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100vh'
                  }}>

                    <ThreeCircles
                      height="100"
                      width="100"
                      color="#9753f7"
                      wrapperStyle={{}}
                      wrapperClass=""
                      visible={true}
                      ariaLabel="three-circles-rotating"
                      outerCircleColor=""
                      innerCircleColor="grey"
                      middleCircleColor=""
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </div>


        )
      }

    </>
  );
}


